<template>
  <div>
    <el-table :data="dataList" style="max-width: 1360px;font-size: 12px;padding: 20px;box-sizing: border-box;" empty-text="No Data"
              :highlight-current-row="false" :highlight-selection-row="false" :header-cell-class-name="'custom-head-cell'"
              :cell-style="tableCellstyle" :row-class-name="'custom-row-bg'">
      <el-table-column prop="pass.name" label="Pass"></el-table-column>
      <el-table-column prop="" label="Expiry Date" width="200px">
        <template slot-scope="scope">
          <span v-if="scope.row.pass">
            <template v-if="scope.row.pass.expired_type == 'expired_by_days'">
              {{ $dayjs(scope.row.pay_time*1000).add(scope.row.pass.expired_value, 'day').format("MMMM DD, YYYY") }}
              <br>
              ({{ scope.row.pass.expired_value }} days after purchase)
            </template>
            <template v-else>
              {{ $dayjs(scope.row.pass.expired_value).format("MMMM DD, YYYY") }}
            </template>
          </span>
          <!-- {{ convertTimeToEng(scope.row.create_time) }} -->
          <!-- {{ $dayjs(scope.row.create_time * 1000).format("MMM DD,&nbsp;YYYY") }} -->
        </template>
      </el-table-column>
      <el-table-column prop="status" label="Status" width="160px">
        <template slot-scope="scope">
          <span :style="getStatusStyle(scope.row.status)">{{statusToEng(scope.row.status)}}</span>
        </template>
      </el-table-column>
      <!-- <el-table-column prop="" label="Result" width="160px"></el-table-column> -->
      <el-table-column prop="" label="# of Projects Redeemed" width="160px">
        <template slot-scope="scope">
          {{ scope.row.applications ? scope.row.applications.length : 0 }}
        </template>
      </el-table-column>
      <el-table-column prop="" label="Operation" width="360px">
        <template slot-scope="scope">
          <!-- v-if="scope.row.application_status == '待缴费'"-->
          <div style="display: flex;align-items: center;justify-content: center;">
            <template v-if="scope.row.status!='未支付'">
              <el-button type="primary" size="small" @click="goToEAEStore" style="background-color: #4CAF50; border-color: #4CAF50;">Use it in store</el-button>
              <el-button size="small" @click="viewDetails(scope.row)" style="background-color: transparent; border: 1px solid var(--c-primary); color: var(--c-primary);">View Details</el-button>
            </template>
            <template v-else>
              <el-button type="primary" size="small" @click="gotoPay(scope.row.pass._id.$id)">Pay</el-button>
              <el-button size="small" @click="doCancel(scope.row._id.$id)">Cancel</el-button>
            </template>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog
        :visible.sync="isShowDialog"
        :title="passInfo.name"
        width="800px"
    >
      <div>
        <div class="pass-info" style="max-height: 300px; overflow-y: auto; padding-right: 10px; margin-bottom: 60px;">
          <p style="margin-top: 10px;">{{passInfo.short_intro}}</p>
          <p style="font-weight: bold; margin-top: 20px;">Benefits and Privileges of this Pass:</p>
          <ul v-if="passInfo.projects && passInfo.projects.length > 0" style="margin-top: 10px;">
            <template v-for="(project, index) in passInfo.projects">
              <li v-if="project.type === 'project_id'" :key="'project_id_' + index" style="margin-top: 5px;">
                ({{ project.count === -1 ? 'Unlimited' : project.count }}) registration in {{ project.project_ename }} 
              </li>
              <li v-else-if="project.type === 'project_BU'" :key="'project_BU_' + index" style="margin-top: 5px;">
                ({{ project.count === -1 ? 'Unlimited' : project.count }}) registrations in {{ project.project_id }} projects
              </li>
              <li v-else :key="'project_other_' + index" style="margin-top: 5px;">
                ({{ project.count === -1 ? 'Unlimited' : project.count }}) {{  project.project_id }} projects
              </li>
            </template>
          </ul>
          <p v-else style="margin-top: 10px;">No specific benefits or privileges listed for this pass.</p>
          <p style="font-weight: bold; margin-top: 20px;">Pass Redemption Records</p>
          <template v-if="recordList.length>0">
            <p v-for="(item,index) in recordList" :key="'record_' + index" style="margin-top: 5px;">{{item.application_payment_date + ' | ' +item.project.project_ename}}</p>
          </template>
          <template v-else>
            <p style="margin-top: 10px;">No Records</p>
          </template>
        </div>

        <div style="width: 100%; text-align: center; position: fixed; bottom: 20px; left: 0; right: 0; background-color: white; padding: 15px 0; box-shadow: 0 -2px 10px rgba(0,0,0,0.1);">
          <el-button type="primary" size="medium" @click="goToEAEStore">Go to the EAE Store and use my pass to enroll in programs.</el-button>
          <el-button size="medium" @click="isShowDialog=false">Close</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  getStudentPurchasdPasses,
  removeStudentPassOrder, createREPassOrder
} from '../../api/eae'
import '../../assets/common/font.css'
import {
  getUserId,
} from '../../utils/store'
import { mapMutations } from 'vuex' // Add this import

export default ({
  name: 'index',
  data() {
    return {
      dataList: [],
      isShowDialog: false,
      passInfo: {},
      recordList: [],
      loading: false
    }
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      
      let loading = this.$loading({
        lock: true,
        text: "Loading...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      getStudentPurchasdPasses(getUserId()).then((res) => {
        this.dataList = res.data
        loading.close();
      }).catch((error) => {
        console.error("Error fetching passes:", error);
        this.$message.error("Failed to load passes. Please try again.");
      }).finally(() => {
        loading.close();
      });
    },
    convertTimeToEng(time) {
      var date = new Date(time * 1000);
      let year = date.getFullYear();
      let day = date.getDate();
      let month = date.getMonth() + 1;
      return this.formatMonthToEng(month) + " " + day + ",&nbsp;" + year;
    },
    statusToEng(data){
      switch (data) {
        case '未支付':
          return "Pending Payment";
        case '已支付':
          return "Active";
        default:
          return "Expired";
      }
    },
    formatMonthToEng(month) {
      switch (month) {
        case 1:
          return "January";
        case 2:
          return "February";
        case 3:
          return "March";
        case 4:
          return "April";
        case 5:
          return "May";
        case 6:
          return "June";
        case 7:
          return "July";
        case 8:
          return "August";
        case 9:
          return "September";
        case 10:
          return "October";
        case 11:
          return "November";
        case 12:
          return "December";
      }
    },

    doCancel(id) {
      this.$confirm("Are you sure you want to cancel this purchase?", "Cancel Purchase", { type: "warning", confirmButtonText: "Confirm", })
          .then(() => {
            removeStudentPassOrder(id).then((res) => {
              this.$message.success('Pass Order Canceled Successfully')
              this.fetchData()
            })
            this.$emit("remove");
          })
    },
    gotoPay(application_id) {
      createREPassOrder(getUserId(), application_id,'EAE-PASS').then((res) => {
        let result = res.data
        if (result.code == 0) {
          this.gotoOrderView(result.data._id.$id)
        } else if (result.code == -1) {
          let order_status = result.data.status
          if (order_status == '未支付') {
            this.gotoOrderView(result.data._id.$id)
          } else {
            this.$message.success('You have already purchased this pass')
          }
        } else {
          this.$message.warning('You have already purchased this pass')
        }
      })
    },
    gotoOrderView(order_id) {
      this.$router.push({
        path: '/home/pass-order',
        query: {
          order_id: order_id
        }
      })
    },
    tableCellstyle() {
      return 'background:  #FAE3E4;border:1px solid white;text-align:center; font-family: Ubuntu-Bold;'
    },
    viewDetails(data){
      console.log(data,'datatatat')
      this.passInfo = data.pass
      this.recordList = data.applications
      this.isShowDialog = true
    },
    goToEAEStore() {
      //TODO胡老师解决
      // Update the selectedIndex in Home.vue
      let path = '/home/store'
      this.$globalMessage.$emit("homeNavMenuChanged",path);
      this.$router.push({
        path: path
      })
    },
    getStatusStyle(status) {
      if (status == '未支付') {
        return {color: 'var(--c-primary)'}
      } else if (status == '已支付') {
        return {color: 'green'}
      } else {
        return {color: 'red'}
      }
    }
  }
})
</script>

<style scoped lang="scss">
/deep/ .el-table .custom-row-bg {
  background: #FAE3E4;
}

/deep/ .el-table .custom-head-cell {
  background: var(--c-primary);
  color: white;
  text-align: center;
  border: 1px solid white;
  box-sizing: border-box;
  font-family: Ubuntu-Bold;
}
.pass-info{
  line-height: 26px;
  word-break: keep-all;
  p{
    margin-bottom: 10px;
  }
}
</style>